import React from "react"
import SEO from "../../components/seo"
import Header from "../../components/header"

import WorkFooter from "../../components/portfolioItems/worksFooter"


import Herospace from "../../../assets/images/portfolio_items/sigma/sigma.png"
import sigma1 from "../../../assets/images/portfolio_items/sigma/sigma1.png"
import sigma2 from "../../../assets/images/portfolio_items/sigma/sigma2.png"
import sigma3 from "../../../assets/images/portfolio_items/sigma/sigma3.png"

export default () => 
<div>
    <SEO title={'Sigma ICON Worldwide | Sigma Aldrich - Design Project'}
    description="A valuable print and digital design strategy for a global life science leader: Sigma Aldrich. Discover more on ICON's portfolio! "
    canonical={'https://icon-worldwide.com/works/sigma-aldrich'}/>
        <Header bgcolor="#292929"/>
    <div id="portfolio-item">
        <div className="herospace">
            <img src={Herospace} alt="Sigma Aldrich, ICON Worldwide, print and digital design strategy, portfolio" title="ICON Worldwide portfolio, print and digital design strategy, Sigma Aldrich"/>
            <div className="title"><h1>Sigma Aldrich<span></span></h1>
            <h2>A valuable print and digital design project</h2>
            </div>
        </div>
        <div className="portfolio-inner">
        <p>For global Life Science leader Sigma Aldrich ICON has created thousands of print and digital materials.</p>
        <div className="portfolio-images">
            <img src={sigma1} alt="Sigma Aldrich, ICON Worldwide, print and digital design strategy, portfolio" title="ICON Worldwide portfolio, print and digital design strategy, Sigma Aldrich"/>
            <div></div>
            <img src={sigma2} alt="Sigma Aldrich, ICON Worldwide, print and digital design strategy, portfolio" title="ICON Worldwide portfolio, print and digital design strategy, Sigma Aldrich"/>
        </div>
        <img className="full-img" src={sigma3} alt="Sigma Aldrich, ICON Worldwide, print and digital design strategy, portfolio" title="ICON Worldwide portfolio, print and digital design strategy, Sigma Aldrich"/>
        <WorkFooter previous="kanebo-sensai" next="zollinger-stiftung"/>
        </div>
    </div>
</div>